.appView {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  row-gap: 1px;
}

.controllers {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 30px;
  background-color: #000;
  width: 100vw;
  padding: 10px 20px;
}

@supports (padding: max(0px)) {
  .controllers {
    padding:
      10px max(env(safe-area-inset-right), 20px)
      max(env(safe-area-inset-bottom), 10px)
      max(env(safe-area-inset-left), 20px);
  }
}

@media (max-width: 500px) {
  .controllers {
    column-gap: 20px;
    padding: 10px 15px;
  }

  @supports (padding: max(0px)) {
    .controllers {
      padding:
        10px max(env(safe-area-inset-right), 15px)
        max(env(safe-area-inset-bottom), 10px)
        max(env(safe-area-inset-left), 15px);
    }
  }
}
